import * as React from 'react';
import { TextField, Button, FormControl, Grid, FormControlLabel, Switch, Box } from '@material-ui/core';
import { getModalStyle, getModalClasses } from '../../hooks/styles';
import { valueMapper } from '../../hooks/functions';
import { CabinetActions } from '../../redux/actions';
import { CabinetStyles } from './cabinet.styles';
import { connect } from 'react-redux';
import { SelectBox } from '../../components/selectBox';
import { useMediaQuery } from 'react-responsive';
import dataList from '../../constants/dataList';
import Loading from '../../components/loading';
import { formatCabinetPropertyValue } from '../../hooks/functions/CabinetPropertyUtils';
import { startCase, snakeCase } from 'lodash';

const CabinetForm: React.FC<any> = props => {
	const isDesktopOrLaptop = useMediaQuery({
		query: '(min-device-width: 1224px)',
	});
	const modalClasses = getModalClasses();
	const classes = CabinetStyles();
	const [cabinet, setCabinet] = React.useState(props.cabinet || ({} as any));
	const [editedProperties, setEditedProperties] = React.useState<any>({});
	const [nextCabinetId, setNextCabinetId] = React.useState<any>(null);
	const [isConsignment, setIsConsignment] = React.useState<boolean>(false);
	const [refreshingProperties, setRefreshingProperties] = React.useState<boolean>(false);
	const [propertyListError, setPropertyListError] = React.useState<boolean>(false);
	const [isFormComplete, setIsFormComplete] = React.useState<boolean>(false);
	const [errors, setErrors] = React.useState({} as any);
	const requiredProperties = ['viewpointAssetId', 'serverName', 'mbrxPracticeId', 'enclosureSerialNo'];

	const fetchCabinetTypeProperties = () => {
		setRefreshingProperties(true);
		props.getCabinetTypeProperties({
			cabinetType: cabinet.cabinetType,
			success: () => {
				setRefreshingProperties(false);
			},
			error: () => {
				setRefreshingProperties(false);
				setPropertyListError(true);
			},
		});
	};
	const fetchNextCabinetId = () => {
		props.getNextCabinetId(cabinet.cabinetType);
	}
	React.useEffect(() => {
		setEditedProperties({});
		if (!cabinet.cabinetType) {
			return;
		}
		fetchCabinetTypeProperties();
		fetchNextCabinetId();
		

	}, [cabinet.cabinetType]);

	React.useEffect(() => {
		if (!props.nextCabinetId) {
			return;
		}
		console.log("useEffect - nextCabinetId - " + props.nextCabinetId);
		handleInput('cabinetId', props.nextCabinetId);
	}, [props.nextCabinetId]);

	
	React.useEffect(() => {
		validateForm();
	}, [cabinet.cabinetType, cabinet.cabinetName, cabinet.cabinetState, cabinet.tempControl]);

	const validateForm = () => {
		setIsFormComplete(
			Boolean(cabinet.cabinetType && cabinet.cabinetName && cabinet.cabinetState && cabinet.tempControl),
		);
	};

	const getPropertySelectBoxOptions = (property: any) => {
		return property.values.split(',').map((value: string) => {
			return { label: formatCabinetPropertyValue(property.property_key, value), value: value };
		});
	};

	const onPropertyValueChange = (propertyKey: string, newValue: any) => {
		setEditedProperties({ ...editedProperties, [propertyKey]: newValue || null });
		setErrors({ ...errors, [propertyKey]: null });
	};

	const getPropertyInputField = (property: any, index: number) => {
		if (!property) return;

		if (property.values) {
			const propertySelectBoxOptions = getPropertySelectBoxOptions(property);
			return (
				<Grid item xs={6} key={index}>
					<FormControl key={index} variant="outlined" className={classes.formControl}>
						<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
							<SelectBox
								inputLabel={startCase(property.property_key)}
								emptyItemLabel={'Choose'}
								listItems={propertySelectBoxOptions}
								onChangeItem={value => onPropertyValueChange(property.property_key, value)}
								style={{ width: '200px' }}
								required={requiredProperties.includes(property.property_key)}
								error={errors[property.property_key] ? true : false}
								errorText={errors[property.property_key] ? errors[property.property_key] : ''}
							/>
						</Box>
					</FormControl>
				</Grid>
			);
		}

		if (property.value_type === 'boolean') {
			return (
				<Grid item xs={6} key={index}>
					<FormControl key={index} className={classes.formControl}>
						<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
							<FormControlLabel
								control={
									<Switch
										checked={editedProperties && editedProperties[property.property_key] ? true : false}
										onChange={() => {
											const currentValue =
												editedProperties[property.property_key] === true ||
												editedProperties[property.property_key] === '1' ||
												editedProperties[property.property_key] === 'true'
													? true
													: false;
											const newValue = !currentValue;
											onPropertyValueChange(property.property_key, newValue);
										}}
										name={property.property_key}
									/>
								}
								label={startCase(property.property_key)}
							/>
						</Box>
					</FormControl>
				</Grid>
			);
		}

		return (
			<Grid item xs={6} key={index}>
				<FormControl key={index} variant="outlined" className={classes.formControl}>
					<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
						<TextField
							style={{ width: 200 }}
							type={property.value_type === 'string' ? 'text' : 'number'}
							label={startCase(property.property_key)}
							onChange={e => onPropertyValueChange(property.property_key, e.target.value)}
							placeholder=""
							variant="outlined"
							required={requiredProperties.includes(property.property_key)}
							error={errors[property.property_key] ? true : false}
							helperText={errors[property.property_key] ? errors[property.property_key] : ''}
						/>
					</Box>
				</FormControl>
			</Grid>
		);
	};
	const handleInput = (field: string, value: any) => {
		setCabinet({ ...cabinet, ...{ [field]: value || '' } });
		if (value) {
			setErrors({ ...errors, ...{ [field]: null } });
		}
	};

	const getParsedProperties = () => {
		const propKeys = Object.keys(editedProperties);
		let payloadProperties: any = {};
		propKeys.forEach((key: string) => {
			let value = editedProperties[key];
			if (key === 'consignment') {
				value = editedProperties[key] === true ? 1 : 0;
			}
			if (value) {
				payloadProperties[snakeCase(key)] = value;
			}
		});
		if (Object.keys(payloadProperties).length === 0) {
			return null;
		}
		return payloadProperties;
	};

	const submit = () => {
		let formErrors: any = {};
		const cabinetFields = [
			{ label: 'Cabinet Name', key: 'cabinetName' },
			{ label: 'Cabinet Status', key: 'cabinetState' },
		];
		cabinet.cabinetType === 'RFID' && cabinetFields.push({ label: 'Device Id', key: 'cabinetId' });

		!isConsignment && cabinetFields.push({ label: 'Cabinet Mode', key: 'tempControl' });

		cabinetFields.forEach((field: { label: string; key: string }) => {
			if (!cabinet[field.key]) {
				formErrors[field.key] = `${field.label} is required.`;
			}
		});

		props.cabinetTypeProperties.forEach((cabinetProp: any) => {
			if (requiredProperties.includes(cabinetProp.property_key) && !editedProperties[cabinetProp.property_key]) {
				formErrors[cabinetProp.property_key] = `${startCase(cabinetProp.property_key)} is required.`;
			}
		});

		if (cabinet.cabinetType === 'RFID' && !editedProperties['enclosureSerialNo']) {
			formErrors['enclosureSerialNo'] = 'Enclosure Serial No is required.';
		}

		if (Object.keys(formErrors).length > 0) {
			setErrors(formErrors);
			return;
		}

		let payloadCabinet: any = { ...cabinet, customerId: props.selectedCustomer.customerId };
		const payloadProperties = getParsedProperties();
		if (payloadProperties) {
			payloadCabinet.properties = payloadProperties;
		}

		const payload = {
			cabinet: payloadCabinet,
			close: props.closeModal,
		};
		props.createCabinet(payload);
	};

	return (
		<div style={{ ...getModalStyle(), width: isDesktopOrLaptop ? 'auto' : '90%' }} className={modalClasses.paper}>
			<div style={{ width: '440px' }}>
				<h2 className={classes.cabinetFormHeading}>Create Device</h2>
				<div className={classes.cabinetFormSubHeading}>
					{props.cabinet ? '' : `For ${props.selectedCustomer.name} (${props.selectedCustomer.customerId})`}
				</div>
				<br />
				<br />
				<form noValidate autoComplete="off">
					<FormControl className={classes.formControl}>
						<TextField
							label="Device Name"
							variant="outlined"
							style={{ width: 200 }}
							value={cabinet.cabinetName || ''}
							error={errors.cabinetName ? true : false}
							helperText={errors.cabinetName || ''}
							onChange={event => {
								handleInput('cabinetName', event.target.value);
							}}
							required
						/>
					</FormControl>
					<FormControl variant="outlined" className={classes.formControl}>
						<SelectBox
							style={{ width: 200 }}
							inputLabel="Device Type"
							emptyItemLabel={'Choose'}
							listItems={dataList.CabinetTypeDropdownList.filter((cabinetType: any) => cabinetType.value !== 'WEIGHT')}
							onChangeItem={(value: string) => {
								handleInput('cabinetType', value);
							}}
							error={errors.cabinetType ? true : false}
							errorText={errors.cabinetType || ''}
							selected={cabinet.cabinetType}
							required
						/>
					</FormControl>
					<div>
						<FormControl required variant="outlined" className={classes.formControl}>
							<SelectBox
								style={{ width: 200 }}
								inputLabel={'Temperature Control'}
								emptyItemLabel={'Choose Temperature Control'}
								listItems={[
									{ label: 'Ambient', value: 'Ambient' },
									{ label: '2° - 8° C', value: '2-8c' },
									{ label: 'Frozen', value: 'Frozen' },
								]}
								onChangeItem={(value: string) => {
									handleInput('tempControl', value);
								}}
								required
								error={errors.tempControl ? true : false}
								errorText={errors.tempControl || ''}
							/>
						</FormControl>
						<FormControl variant="outlined" className={classes.formControl}>
							<SelectBox
								style={{ width: 200 }}
								inputLabel="Device Status"
								emptyItemLabel={'Choose'}
								listItems={dataList.cabinetStates.map((state: string) => ({
									label: valueMapper(state, 'cabinetStates'),
									value: state,
								}))}
								onChangeItem={(value: string) => {
									handleInput('cabinetState', value);
								}}
								error={errors.cabinetState ? true : false}
								errorText={errors.cabinetState || ''}
								required
							/>
						</FormControl>
					</div>
					{/* Cabinet Type Specific Inputs */}
					{refreshingProperties && <Loading message="Loading device type properties" />}
					{propertyListError && <p className={classes.cabinetFormError}>Failed to load device type properties.</p>}

					<Grid container spacing={0}>
						{!refreshingProperties && cabinet.cabinetType === 'RFID' && (
							<>
								<Grid item xs={6}>
									<FormControl key={'cabinetId'} variant="outlined" className={classes.formControl}>
										<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
											<TextField
												style={{ width: 200 }}
												type={'text'}
												label={'Device Id'}
												value={cabinet.cabinetId}
												onChange={event => {
													handleInput('cabinetId', event.target.value);
												}}
												placeholder=""
												variant="outlined"
												required
												error={errors['cabinetId'] ? true : false}
												helperText={errors['cabinetId'] ? errors['cabinetId'] : ''}
											/>
										</Box>
									</FormControl>
								</Grid>
								<Grid item xs={6}>
									<FormControl key={'enclosureSerialNo'} variant="outlined" className={classes.formControl}>
										<Box style={{ display: 'flex', alignItems: 'flex-end' }}>
											<TextField
												style={{ width: 200 }}
												type={'text'}
												label={startCase('enclosureSerialNo')}
												onChange={e => onPropertyValueChange('enclosureSerialNo', e.target.value)}
												placeholder=""
												variant="outlined"
												required={requiredProperties.includes('enclosureSerialNo')}
												error={errors['enclosureSerialNo'] ? true : false}
												helperText={errors['enclosureSerialNo'] ? errors['enclosureSerialNo'] : ''}
											/>
										</Box>
									</FormControl>
								</Grid>
							</>
						)}
						{cabinet.cabinetType &&
							!propertyListError &&
							(props.cabinetTypeProperties || []).map((property: any, index: number) => {
								return getPropertyInputField(property, index);
							})}
					</Grid>
					<div>
						<FormControl className={classes.formControl}>
							<Button
								className={classes.searchButton}
								style={{ marginTop: 12 }}
								variant="contained"
								color="secondary"
								disabled={refreshingProperties || !isFormComplete}
								onClick={submit}
							>
								SUBMIT
							</Button>
						</FormControl>
						<FormControl className={classes.formControl}>
							<Button
								className={classes.cancelBtn}
								variant="contained"
								color="default"
								onClick={() => {
									props.closeModal();
								}}
							>
								CANCEL
							</Button>
						</FormControl>
					</div>
					{props.error && (
						<p className={classes.cabinetFormError}>Failed to {props.cabinet ? 'update' : 'create'} cabinet.</p>
					)}
				</form>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	cabinetTypeProperties: state.cabinet.cabinetTypePropertyList,
	authUser: state.user.authUser,
	selectedCustomer: state.ui.selectedCustomer,
	error: state.cabinet.error,
	nextCabinetId: state.cabinet.nextCabinetId
});

const mapDispatchToProps = (dispatch: any) => ({
	getCabinetTypeProperties: (payload: any) => dispatch(CabinetActions.getPropertiesByCabinetType(payload)),
	createCabinet: (payload: any) => dispatch(CabinetActions.createCabinet(payload)),
	getNextCabinetId: (cabinetType: string) => dispatch(CabinetActions.getNextCabinetId(cabinetType))
});

export default connect(mapStateToProps, mapDispatchToProps)(CabinetForm);
